import { BaseElement, BaseText, Descendant } from "slate";

export enum SuggestedLinkType {
  EVENT_REGISTRATION = "event-registration",
  EVENT_TICKET_RESALE = "event-ticket-resale",
  EVENT_TICKETS = "event-tickets",
  ACCESS_YOUR_TICKETS = "access-your-tickets",
  MEMBERSHIP_RENEWAL = "membership-renewal",
  EVENT_INFORMATION = "event-information",
  REPORT_SPAM = "report-spam",
  UNSUBSCRIBE = "unsubscribe",
  EVENT_OR_MEMBERSHIP_TICKETS = "event-membership",
  ABANDONED_CART_REENGAGEMENT = "abandoned-cart-reengagement",
  EVENT_TERMS_AND_CONDITIONS = "event-terms-and-conditions",
  REGISTRATION_CONFIRMATION_SHARE = "registration-confirmation-share",
  MEMBERSHIP_TERMS_AND_CONDITIONS = "membership-terms-and-conditions",
  GUEST_TICKETS = "guest-tickets",
  EXHIBITOR_LOGIN = "exhibitor-login",
  EXHIBITOR_SCANS = "exhibitor-attendee-scans",
}

export interface Unremovable {
  isRemovable?: false;
}

export type AlignedElement = BaseElement & {
  align?: "left" | "center" | "right";
};

export interface ButtonElement extends AlignedElement, Unremovable {
  type: "button";
  content?: string;
  url?: string;
  eventId?: string;
  membershipId?: string;
  suggestedLink?: SuggestedLinkType;
  releaseId?: string;
  noTrack?: boolean;
  variant?: "secondary";
  useLinkAsDisplayText?: boolean;
}
export interface SelectOption {
  value: string;
  label: string;
  image: string;
  price?: number;
  url?: string;
}

export type ImageSize = "full" | "large" | "medium" | "small";

interface BaseImage extends AlignedElement {
  href?: string;
  size: ImageSize;
}

export interface DynamicImageProperties {
  type: "eventBanner" | "membershipBanner";
}

export interface MerchandiseElement extends BaseElement {
  type: "merchandise";
  items: SelectOption[];
}

export interface ImageElement extends BaseImage, Unremovable {
  type: "image";
  dynamic?: DynamicImageProperties;
  url?: string;
}

export interface LinkElement extends AlignedElement, Unremovable {
  shortUrl?: string;
  type: "link";
  content?: string;
  url?: string;
  eventId?: string;
  suggestedLink?: SuggestedLinkType;
  releaseId?: string;
  membershipId?: string;
  noTrack?: boolean;
  variant?: "secondary";
  useLinkAsDisplayText?: boolean;
}

export interface FieldElement extends BaseElement, Unremovable {
  type: "field";
  content: FieldTextVariables;
}

export interface OrderedListElement extends BaseElement {
  type: "numbered-list";
  children: ListItemElement[];
}

export interface UnorderedListElement extends BaseElement {
  type: "bulleted-list";
  children: ListItemElement[];
}

export interface ListItemElement extends AlignedElement {
  type: "list-item";
  children: Descendant[];
}

export type SummaryType =
  | "auto-renewal-details"
  | "order-details"
  | "competition-prize-details"
  | "ticket-details"
  | "ticket-delay";

export interface SummaryElement extends BaseElement, Unremovable {
  type: "summary";
  content: SummaryType;
}

export interface HeadingOneElement extends AlignedElement {
  type: "heading-one";
  children: Descendant[];
}

export interface HeadingTwoElement extends AlignedElement {
  type: "heading-two";
  children: Descendant[];
}

export interface HeadingThreeElement extends AlignedElement {
  type: "heading-three";
  children: Descendant[];
}

export interface ParagraphElement extends AlignedElement, Unremovable {
  type: "paragraph";
  variant?: "secondary";
  children: Descendant[];
}

export interface SpacerElement extends BaseElement {
  type: "spacer";
  height: number;
}

export interface QRCodeElement extends AlignedElement {
  type: "qrCode";
  data: string;
  size: ImageSize;
}

export interface DividerElement extends BaseElement, Unremovable {
  type: "divider";
}

export interface VideoElement extends BaseElement {
  type: "video";
  url: string;
}

export interface BoxElement extends BaseElement {
  type: "box";
  children: Descendant[];
}

export interface TicketsToSendElement extends AlignedElement {
  type: "tickets-to-send";
}

export type CustomElement =
  | ButtonElement
  | ImageElement
  | LinkElement
  | FieldElement
  | OrderedListElement
  | UnorderedListElement
  | ListItemElement
  | SpacerElement
  | DividerElement
  | ParagraphElement
  | HeadingOneElement
  | HeadingTwoElement
  | HeadingThreeElement
  | SummaryElement
  | VideoElement
  | BoxElement
  | MerchandiseElement
  | QRCodeElement
  | TicketsToSendElement;

export type TextNode = BaseText & {
  bold?: boolean;
  italic?: boolean;
};

export enum FieldTextVariables {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  EVENT_NAME = "eventName",
  MEMBERSHIP_NAME = "membershipName",
  ITEMS_PURCHASED = "itemsPurchased",
  ORDER_NUMBER_EVENT_NAME = "orderNumberEventName",
  ORDER_NUMBER_MEMBERSHIP_NAME = "orderNumberMembershipName",
  // used by the send-to-a-friend template, this is the name of the ticket sender
  CUSTOMER_NAME = "customerName",
  TICKETS_TO_SEND = "ticketsToSend",
}
