import Select, { components, GroupType, OptionProps } from "react-select";
import { useTheme } from "styled-components";
import useLayoutContext from "../../../hooks/useLayoutContext";
import {
  allMemberships,
  groupMemberships,
  isAllMemberships,
  MembershipOptionType,
} from "../util";
import {
  CommonDisplay,
  defaultComponents,
  renderGroup,
  selectStyles,
} from "./common";
import { SearchItem } from "../SearchItem";
import { PRIMARY_NAVIGATION_KEYS } from "~features/reports/reporting/navigation/primary.config";
import { SourceSelection } from "~features/reports/reporting/navigation/useNavigation";
import { Skeleton } from "~components";
import { Box, Text } from "flicket-ui";
import { DATE_FORMAT } from "~lib/helpers/dates/formatDateRange";
import { formatDate, notEmptyPredicate } from "~lib";

interface MembershipSelectProps {
  onChange: (option: SourceSelection) => void;
}

export const Option = (props: OptionProps<MembershipOptionType, false>) => {
  return (
    <components.Option {...props}>
      {isAllMemberships(props.data) ? (
        <SearchItem active={props.isFocused}>All memberships</SearchItem>
      ) : (
        <SearchItem active={props.isFocused}>{props.data.name}</SearchItem>
      )}
    </components.Option>
  );
};

function Subtitle() {
  const context = useLayoutContext();

  if (context.pageState === "layoutLoading") {
    return <Skeleton height={15} maxWidth={"400px"} mt={"6/4"} />;
  }

  if (
    context.type !== PRIMARY_NAVIGATION_KEYS.MEMBERSHIPS ||
    !context.membershipInformation
  )
    return null;

  const date = [
    context.membershipInformation?.startDate,
    context.membershipInformation?.endDate,
  ]
    .filter(notEmptyPredicate)
    .map((d) => formatDate(d, DATE_FORMAT))
    .join(" - ");

  return (
    <Box mt={1}>
      {date && <Text variant="small">{date}</Text>}

      {context.membershipInformation?.location && (
        <Text variant="small">{context.membershipInformation?.location}</Text>
      )}
    </Box>
  );
}

export function MembershipDisplay({ small }: { small?: boolean }) {
  const context = useLayoutContext();

  if (context.type !== PRIMARY_NAVIGATION_KEYS.MEMBERSHIPS) {
    return null;
  }

  let image = undefined;
  let title;

  if (context.isAllMemberships) {
    image = null;
    title = allMemberships.label;
  } else {
    const membershipId = context.membershipId;
    image = context.membershipInformation?.banner;
    title =
      context.membershipInformation?.name ??
      context.searchableMemberships.find((s) => s.id === membershipId)?.name;
  }

  return (
    <CommonDisplay
      image={image}
      title={title}
      small={small}
      subtitle={<Subtitle />}
      sourceLabel={context.type}
    />
  );
}

export default function MembershipSelect(props: MembershipSelectProps) {
  const theme = useTheme();
  const context = useLayoutContext();

  const options: (
    | MembershipOptionType
    | GroupType<MembershipOptionType>
  )[] = [];

  options.push(allMemberships);
  options.push(...groupMemberships(context.searchableMemberships));

  const onChange = (option: MembershipOptionType) => {
    const selectedSource = isAllMemberships(option)
      ? "all-memberships"
      : { membershipId: option.id };

    props.onChange(selectedSource);
  };

  return (
    <Select
      menuIsOpen
      autoFocus
      options={options}
      getOptionValue={(option: MembershipOptionType) => {
        return option.id;
      }}
      getOptionLabel={(option: MembershipOptionType) => {
        return isAllMemberships(option) ? option.label : option.name;
      }}
      formatGroupLabel={renderGroup}
      onChange={onChange}
      components={{ ...defaultComponents, Option }}
      styles={selectStyles(theme)}
      placeholder="Search memberships"
    />
  );
}
