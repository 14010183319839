import { Dispatch, SetStateAction } from "react";
import { Text } from "flicket-ui";
import { useTheme } from "styled-components";
import { Icon } from "~components";
import { Button } from "../components";
import { PencilSimple, Trash } from "@phosphor-icons/react";
import { Editor } from "slate";

// Popover for buttons and links
export const LinkPopover = ({
  url,
  editor,
  setIsOpen,
  color,
  displayRemoveButton = true,
}: {
  url?: string;
  editor: Editor;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  color?: string;
  displayRemoveButton?: boolean;
}) => {
  const theme = useTheme();
  if (!color) {
    color = "#3869d4";
  }
  return (
    <>
      {url && (
        <Text
          as="a"
          variant="regular"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          contentEditable={false}
          css={`
            color: ${color};
            text-decoration: underline;
            margin-right: ${theme.space[1]}px;
            max-width: 200px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `}
        >
          {url}
        </Text>
      )}

      <Button
        w="30px"
        h="30px"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <Icon icon={<PencilSimple size={18} />} />
      </Button>

      {displayRemoveButton && (
        <Button
          w="30px"
          h="30px"
          onClick={() => {
            editor.deleteBackward("character");
          }}
        >
          <Icon icon={<Trash size={18} />} />
        </Button>
      )}
    </>
  );
};
