import { Descendant, Element, Node } from "slate";
import {
  BoxElement,
  ButtonElement,
  DynamicImageProperties,
  FieldElement,
  ImageElement,
  LinkElement,
  MerchandiseElement,
  ParagraphElement,
  QRCodeElement,
  SummaryElement,
  TicketsToSendElement,
  VideoElement,
} from "./slate.interface";

export function isFieldElement(node: Descendant | Node): node is FieldElement {
  return Element.isElement(node) && node.type === "field" && !!node.content;
}

export function isButtonElement(
  node: Descendant | Node
): node is ButtonElement {
  return Element.isElement(node) && node.type === "button";
}

export function isLinkElement(node: Descendant | Node): node is LinkElement {
  return Element.isElement(node) && node.type === "link";
}

export function isImageElement(node: Descendant | Node): node is ImageElement {
  return Element.isElement(node) && node.type === "image";
}

export function isVideoElement(node: Descendant | Node): node is VideoElement {
  return Element.isElement(node) && node.type === "video";
}

export function isQRCodeElement(
  node: Descendant | Node
): node is QRCodeElement {
  return Element.isElement(node) && node.type === "qrCode";
}

export function isMerchandiseElement(
  node: Descendant | Node
): node is MerchandiseElement {
  return Element.isElement(node) && node.type === "merchandise";
}

export function isParagraphElement(
  node: Descendant | Node
): node is ParagraphElement {
  return Element.isElement(node) && node.type === "paragraph";
}

export function isDynamicImageProperties(
  obj: unknown
): obj is DynamicImageProperties {
  const properties = obj as DynamicImageProperties;
  if (!properties) return false;

  return (
    properties.type === "eventBanner" || properties.type === "membershipBanner"
  );
}

export function isSummaryElement(node: Descendant): node is SummaryElement {
  return Element.isElement(node) && node.type === "summary";
}

export function isBoxElement(node: Descendant): node is BoxElement {
  return Element.isElement(node) && node.type === "box";
}

export function isTicketsToSendElement(
  node: Descendant
): node is TicketsToSendElement {
  return Element.isElement(node) && node.type === "tickets-to-send";
}

export function isSection(node: Descendant): node is BoxElement {
  return isBoxElement(node) || isMerchandiseElement(node);
}
