import styled, { StyledComponentPropsWithRef } from "styled-components";
import {
  Flex,
  Text,
  Checkbox as FlicketCheckbox,
  Stack,
  system,
} from "flicket-ui";
import { StatisticTooltip } from "~features/reports/postevent/components/StatisticTooltip";
import { uniqueId } from "lodash";
import { StatisticTooltipFragment } from "~graphql/typed-document-nodes";
import { FC } from "react";

const StyledFlicketCheckbox = styled(FlicketCheckbox)<{ ellipsis?: boolean }>`
  + label {
    margin-left: 12px;
    ${(p) =>
      p.ellipsis &&
      `
      max-width: 100%;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
    `}
  }

  &:checked {
    border-color: ${(p) => p.theme.colors.N800};

    &:before {
      background-color: ${(p) => p.theme.colors.N800};
    }
  }

  ${system}
`;

interface CheckboxProps
  extends StyledComponentPropsWithRef<typeof StyledFlicketCheckbox> {
  isLastChild?: boolean;
  label?: string;
  description?: string;
  checked?: boolean;
  tooltip?: StatisticTooltipFragment;
}

export const Checkbox: FC<CheckboxProps> = ({
  isLastChild,
  description,
  tooltip,
  ...props
}) => {
  return (
    <Flex
      flexDirection="column"
      maxWidth="100%"
      width="100%"
      mb={isLastChild ? 2 : 0}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Stack gap={1} width={props.ellipsis ? "100%" : "auto"}>
        <StyledFlicketCheckbox {...props} />
        {tooltip && <StatisticTooltip tooltip={tooltip} id={uniqueId()} />}
      </Stack>
      {description && (
        <Text variant="small" ml={"28px" as any}>
          {description}
        </Text>
      )}
    </Flex>
  );
};
