import { SummaryElement, SummaryType } from "./interface/slate.interface";
import { EmptyParagraph } from "./util";
import { LockSimple } from "@phosphor-icons/react";
import { Stack, Text } from "flicket-ui";
import { ReactNode } from "react";
import { Transforms, Range, Editor } from "slate";
import { HistoryEditor } from "slate-history";
import { ReactEditor, RenderElementProps } from "slate-react";
import { useTheme } from "styled-components";
import { Icon } from "../Icon";
import { usePopover } from "./Popover/usePopover";
import { StyledPopover } from "./Popover/Popover";
import { UpDownPopoverButtons } from "./UpDown/UpDown";
import { Skeleton } from "../Skeleton";

const summaryLabelMap: Record<SummaryType, string> = {
  "order-details": "Order details",
  "auto-renewal-details": "Auto-renewal details",
  "ticket-details": "Event details",
  "competition-prize-details": "Competition prize details",
  "ticket-delay": "Ticket delay details",
};

export const insertSummary = (editor: Editor, summaryName: SummaryType) => {
  let blurSelection = editor.blurSelection;
  if (!blurSelection) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }
  const newRange: Range = {
    anchor: {
      offset: 0,
      path: [blurSelection?.anchor.path[0] + 1, 0],
    },
    focus: {
      offset: 0,
      path: [blurSelection?.focus.path[0] + 1, 0],
    },
  };
  const summaryElement: SummaryElement = {
    type: "summary",
    content: summaryName,
    children: [{ text: "" }],
  };

  Transforms.insertNodes(editor, summaryElement, { at: blurSelection });
  Transforms.insertNodes(editor, EmptyParagraph, { at: newRange });
};

export const withSummary = (editor: Editor & ReactEditor & HistoryEditor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "summary" ? true : isVoid(element);
  };

  return editor;
};

type SummaryProps = Pick<RenderElementProps, "attributes"> & {
  children: ReactNode;
  element: SummaryElement;
};

export const Summary = (props: SummaryProps) => {
  const { attributes, element, children } = props;
  const theme = useTheme();
  const { isPopoverOpen, popoverRef } = usePopover("summary");
  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        boxShadow: isPopoverOpen ? `0 0 0 2px ${theme.colors.N800}` : "none",
        borderRadius: theme.radii.md,
        border: `1px solid ${theme.colors.N300}`,
      }}
      contentEditable={false}
      {...attributes}
    >
      <Stack direction={"vertical"} gap={1} padding={3}>
        {element.isRemovable === false ? (
          <Stack
            position="absolute"
            backgroundColor={"N200"}
            right={"24px"}
            width="40px"
            height="40px"
            alignItems="center"
            justifyContent={"center"}
            borderRadius={theme.radii.full as any}
          >
            <Icon icon={<LockSimple />} />
          </Stack>
        ) : null}
        <Text variant="header.XS">{summaryLabelMap[element.content]}</Text>
        <Stack direction={"vertical"}>
          <Skeleton duration={0} width={"70%"} height={"16px"} />
          <Skeleton duration={0} width={"88%"} height={"16px"} />
          <Skeleton duration={0} width="80%" height={"16px"} />
        </Stack>
      </Stack>
      {children}
      {isPopoverOpen && (
        <StyledPopover ref={popoverRef}>
          <UpDownPopoverButtons />
        </StyledPopover>
      )}
    </div>
  );
};
