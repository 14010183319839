import { yupResolver } from "@hookform/resolvers";
import {
  Box,
  ErrorMessage,
  Input,
  PrimaryButton,
  Stack,
  TertiaryButton,
  UnderlineButton,
} from "flicket-ui";
import { FormEvent, ReactNode } from "react";
import {
  Controller,
  ControllerRenderProps,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import styled from "styled-components";
import Modal from "~components/common/CustomModal/CustomModal";
import {
  ImageUpload,
  ImageUploadEmptyState,
  ImgPreview,
} from "~features/generalAdmissionEvent/components/FormPartials/ImageUpload.form-partial";
import { FormValues, buildSchema } from "./schema";
import { EmailImageLoading } from "../Image";
import { useFormWithSubmitState } from "~lib/helpers/form/useFormWithSubmitState";
import { useQuery } from "~hooks/useQuery";
import {
  DynamicImageDocument,
  ExtendedFile,
} from "~graphql/typed-document-nodes";
import {
  DynamicImageProperties,
  ImageElement,
} from "../interface/slate.interface";
import { isDynamicImageProperties } from "../interface/slate.predicates";
import useEmailDataContext from "~features/emailCustomisation/hooks/useEmailDataContext";

const StyledImageUploadEmptyState = styled(ImageUploadEmptyState)<{
  error?: boolean;
}>`
  border: 1px solid ${(p) => p.theme.colors.error};
  ${(p) => !p.error && `border: none;`}
`;

interface ResolveDynamicImage {
  value?: null | DynamicImageProperties | ExtendedFile | undefined;
  children: (props: {
    resolvedValue: null | ExtendedFile | undefined;
    isLoading: boolean;
  }) => ReactNode;
}
export function ResolveDynamicImage(props: ResolveDynamicImage) {
  const { value, children } = props;

  const context = useEmailDataContext();
  const isDynamicImage = isDynamicImageProperties(value);

  const { data, isLoading } = useQuery(
    isDynamicImage && DynamicImageDocument,
    isDynamicImage && {
      input: {
        eventId: context.type === "event" ? context.id : undefined,
        membershipId: context.type === "membership" ? context.id : undefined,
      },
    }
  );

  return (
    <>
      {children({
        resolvedValue: isDynamicImage ? data?.dynamicImage : value,
        isLoading,
      })}
    </>
  );
}

export function ImageUploadField({
  name,
  description,
  ctaText = "Upload banner image",
  defaultValue = undefined,
  aspectRatio = 2 / 1,
  error,
}: {
  name: string;
  description: ReactNode;
  aspectRatio?: number;
  ctaText?: ReactNode;
  cropImage?: boolean;
  defaultValue?: unknown;
  error?: string;
}) {
  const ctx = useFormContext();
  const { control } = ctx;

  const dynamicImage = ctx.watch("dynamicImage");

  return (
    <Controller
      defaultValue={defaultValue}
      control={control}
      name={name}
      render={(props: ControllerRenderProps<Record<string, any>>) => {
        // This is the chosen image OR the overridden one if this is a dynamic node.
        const uploadedImage = props.value;
        return (
          <ResolveDynamicImage value={uploadedImage ?? dynamicImage}>
            {({ resolvedValue, isLoading }) => {
              if (isLoading) {
                return <EmailImageLoading />;
              }

              return (
                <ImageUpload {...props} value={resolvedValue}>
                  {({
                    handleImageSelect,
                    removeImage,
                    hasImage,
                    previewImageUrl,
                  }) => (
                    <Stack direction={"vertical"}>
                      {!hasImage && (
                        <StyledImageUploadEmptyState
                          aspectRatio={aspectRatio}
                          handleImageSelect={handleImageSelect}
                          description={description}
                          ctaText={ctaText}
                          error={!!error}
                        />
                      )}

                      {hasImage && (
                        <Stack direction={"vertical"} gap={2}>
                          <ImgPreview
                            src={previewImageUrl}
                            title={"Event banner"}
                          />
                          <Stack gap={2} justifyContent={"flex-end"}>
                            {dynamicImage && (
                              <>
                                {/* Only show reset to default if the dynamic image has been overridden */}
                                {uploadedImage && (
                                  <UnderlineButton
                                    onClick={removeImage}
                                    fontSize={3}
                                    color={"P300"}
                                    fontWeight={"regular"}
                                    textDecoration={"underline"}
                                  >
                                    Reset to default
                                  </UnderlineButton>
                                )}
                                <UnderlineButton
                                  onClick={() => handleImageSelect()}
                                  fontSize={3}
                                  color={"P300"}
                                  fontWeight={"regular"}
                                  textDecoration={"underline"}
                                >
                                  Upload image
                                </UnderlineButton>
                              </>
                            )}

                            {!dynamicImage && (
                              <UnderlineButton
                                onClick={removeImage}
                                fontSize={3}
                                color={"P300"}
                                fontWeight={"regular"}
                                textDecoration={"underline"}
                                alignSelf={"flex-end"}
                              >
                                Remove
                              </UnderlineButton>
                            )}
                          </Stack>
                        </Stack>
                      )}
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </Stack>
                  )}
                </ImageUpload>
              );
            }}
          </ResolveDynamicImage>
        );
      }}
    />
  );
}

export interface InsertImageFormProps {
  close: () => void;
  onSubmit: (values: FormValues) => Promise<void>;
  afterSubmit?: () => void;
  image: ImageElement;
}

export default function InsertImageForm(props: InsertImageFormProps) {
  const { close, onSubmit, afterSubmit, image } = props;

  const { schema, formNames, defaultValues } = buildSchema(image);

  const _onSubmit = async (values: FormValues) => {
    await onSubmit(values);
    afterSubmit();
  };

  const methods = useFormWithSubmitState({
    onSubmit: _onSubmit,
    onError: console.error,
    defaultValues,
    resolver: yupResolver(schema),
  });

  return (
    <>
      <FormProvider {...methods}>
        <Modal.Header>Insert image</Modal.Header>
        <Modal.Content>
          <Box
            id="insert-image"
            as="form"
            onSubmit={(e: FormEvent) => {
              e.stopPropagation();
              void methods.handleFormSubmit(e);
            }}
          >
            <Stack direction="vertical" gap={3}>
              <ImageUploadField
                name={"image"}
                ctaText="Upload image"
                description="Supported files JPEG, PNG or GIF"
                error={methods.errors?.image?.message}
              />
              <Controller
                as={Input}
                control={methods.control}
                name={formNames.imageLink}
                label="Image link (URL)"
                error={methods.errors?.imageLink?.message}
              />
            </Stack>
          </Box>
        </Modal.Content>
        <Modal.Footer>
          <Stack gap={1}>
            <TertiaryButton onClick={close} disabled={methods.isSubmitting}>
              Cancel
            </TertiaryButton>
            <PrimaryButton
              type="submit"
              form="insert-image"
              isLoading={methods.isSubmitting}
            >
              Insert
            </PrimaryButton>
          </Stack>
        </Modal.Footer>
      </FormProvider>
    </>
  );
}
