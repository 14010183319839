import { createContext } from "react";
import { SourceSearchIndexQuery } from "~graphql/sdk";

export type SearchIndexContext = {
  reportingSearchIndex: {
    searchableEvents: SourceSearchIndexQuery["reportingSearchIndex"]["searchableEvents"];
    searchableMemberships: SourceSearchIndexQuery["reportingSearchIndex"]["searchableMemberships"];
  };
};
export const SearchIndexContext = createContext<SearchIndexContext>(null);
