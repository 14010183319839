import {
  EmptyParagraph,
  topOfDocumentSelection,
} from "~components/common/RichText/util";
import { Editor, Text as TextNode, Transforms } from "slate";
import isEqual from "lodash/isEqual";
import {
  isMerchandiseElement,
  isParagraphElement,
} from "~components/common/RichText/interface/slate.predicates";
import { ReactEditor, RenderElementProps, useSlate } from "slate-react";
import { MerchandiseElement } from "~components/common/RichText/interface/slate.interface";
import { Box, Flex, system, Text } from "flicket-ui";
import NextImage from "next/future/image";
import styled from "styled-components";
import { usePopover } from "~components/common/RichText/Popover/usePopover";
import { MerchandisePopover } from "~components/common/RichText/Popover/MerchandisePopover";
import { HistoryEditor } from "slate-history";
import { GroupInsertOptions } from "~components/common/RichText/Dropdown";
import { StyledPopover } from "./Popover/Popover";
import { Icon } from "../Icon";
import { PencilSimple, Trash } from "@phosphor-icons/react";
import { merchandiseModalState } from "./InsertMerchandiseModal";
import { useAtom } from "jotai";
import { Button } from "./components";

export const excludeMerchandise = (options: GroupInsertOptions[]) => {
  return options
    .map((group) => ({
      ...group,
      options: group.options.filter((option) => option.value !== "merchandise"),
    }))
    .filter((group) => group.options.length > 0);
};

export const editMerchandise = (
  editor: Editor,
  merchandise: MerchandiseElement
) => {
  const blurSelection = editor.blurSelection;
  if (!blurSelection) {
    console.error("Cannot edit link without selection.");
    return;
  }
  Transforms.setNodes(editor, merchandise, {
    at: blurSelection,
    match: (n) => isMerchandiseElement(n),
    mode: "lowest",
  });
  ReactEditor.focus(editor);
};

export const insertMerchandise = (
  editor: Editor,
  merchandise: MerchandiseElement
) => {
  let blurSelection = editor.blurSelection;
  if (!blurSelection) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }
  Transforms.insertNodes(editor, merchandise, {
    at: blurSelection,
  });

  // If this is at the top of the document
  if (isEqual(blurSelection, topOfDocumentSelection)) {
    const node = editor.children[blurSelection.anchor.path[0]];
    if (
      isParagraphElement(node) &&
      TextNode.isText(node.children[0]) &&
      node.children[0].text === ""
    ) {
      Transforms.removeNodes(editor, {
        at: blurSelection,
      });
      Transforms.insertNodes(editor, EmptyParagraph, {
        at: blurSelection,
      });
    }
    editor.selection = blurSelection;
  }
  // add a paragraph so the user can edit access the next line in the editor
  Transforms.insertNodes(editor, EmptyParagraph);
  ReactEditor.focus(editor);
};

const Grid = styled(Flex)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 40px;
  margin-top: 10px;

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.xs}) {
    grid-template-columns: 100%;
  }

  ${system}
`;

const CustomerBox = styled(Box)<{ isPopoverOpen: boolean }>`
  position: relative;
  width: 100%;
  border-radius: 4px;
  box-shadow: ${(p) =>
    p.isPopoverOpen ? `0 0 0 2px ${p.theme.colors.N800}` : "none"};
  padding: 3px;
  margin-bottom: 15px;
  span {
    width: 100%;
    display: block;
    height: 100%;
    position: absolute;
    z-index: 1000;
  }
`;

const ImageContainer = styled(Box)`
  width: 150px;
  height: 150px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.xs}) {
    width: 88px;
    height: 88px;
  }
`;

const ItemContainer = styled(Box)`
  width: 100%;
  position: relative;

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.xs}) {
    display: Grid;
    grid-template-columns: 100px 1fr;
  }
`;

export const withMerchandise = (
  editor: Editor & ReactEditor & HistoryEditor
) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "merchandise" ? true : isVoid(element);
  };

  return editor;
};

interface EditMerchandiseButtonProps {
  merchandise: MerchandiseElement | null;
}

export function EditMerchandiseToolbarButton(
  props: EditMerchandiseButtonProps
) {
  const { merchandise } = props;
  const [, setMerchandiseModalState] = useAtom(merchandiseModalState);

  if (!merchandise) {
    return null;
  }

  return (
    <>
      <Button
        onClick={() =>
          setMerchandiseModalState({
            isOpen: true,
            merchandise,
          })
        }
      >
        <Icon icon={<PencilSimple />} mr={"1/2"} />
        <Text variant="regular">Edit</Text>
      </Button>
    </>
  );
}

export function DeleteMerchandiseToolbarButton() {
  const editor = useSlate();

  return (
    <Button
      onClick={() => {
        editor.deleteBackward("block");
      }}
    >
      <Icon icon={<Trash />} mr={"1/2"} />
      <Text variant="regular">Delete</Text>
    </Button>
  );
}

export const MerchandiseComponents = ({
  element,
  children,
  attributes,
  color,
}: RenderElementProps & {
  element: MerchandiseElement;
  color: string;
}) => {
  const editor = useSlate();
  const { isPopoverOpen, popoverRef } = usePopover("merchandise");

  return (
    <CustomerBox
      isPopoverOpen={isPopoverOpen}
      {...attributes}
      cursor="pointer"
      onDragStart={(event) => event.preventDefault()}
      contentEditable={false}
    >
      {children}
      <Grid>
        {element.items.map((item, index) => (
          <ItemContainer key={index}>
            <ImageContainer>
              {item.image ? (
                <NextImage
                  src={item.image}
                  alt={item.value}
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  fill
                  loading="lazy"
                />
              ) : (
                <></>
              )}
            </ImageContainer>
            <Box>
              <Text color={color as any} variant="regular">
                {item.label}
              </Text>
              {item.price && (
                <Text color={color as any} variant="small">
                  ${item.price}
                </Text>
              )}
            </Box>
          </ItemContainer>
        ))}
      </Grid>

      {isPopoverOpen && (
        <StyledPopover ref={popoverRef}>
          <MerchandisePopover merchandiseElement={element} />
        </StyledPopover>
      )}
    </CustomerBox>
  );
};
