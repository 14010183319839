import { Text } from "flicket-ui";
import { useMemo } from "react";
import useLayoutContext, {
  ReportingLayoutContext,
} from "~features/reports/reporting/hooks/useLayoutContext";
import { PRIMARY_NAVIGATION_KEYS } from "~features/reports/reporting/navigation/primary.config";
import { useOrganization } from "~hooks";
import { CommonDisplay } from "./common";

export function OverallDisplay({ small }: { small?: boolean }) {
  const context = useLayoutContext();
  const { organization } = useOrganization();

  if (context.type !== PRIMARY_NAVIGATION_KEYS.OVERALL) {
    return null;
  }

  const image = organization.branding?.logo ?? undefined;
  const title = organization.name;

  const subtitle = useMemo(() => {
    const subtitle = generateSubtitleText(context);
    return subtitle;
  }, [context.searchableEvents.length, context.searchableMemberships.length]);

  return (
    <CommonDisplay
      small={small}
      image={image}
      title={title}
      sourceLabel={context.type}
      subtitle={<Text variant="small">{subtitle}</Text>}
    />
  );
}

function generateSubtitleText(context: ReportingLayoutContext) {
  const eventCount = context.searchableEvents.length;
  const membershipCount = context.searchableMemberships.length;

  const countItems = [
    { name: "event", count: eventCount },
    { name: "membership", count: membershipCount },
  ].filter((item) => item.count > 0);

  if (countItems.length === 0) return "";

  if (countItems.length === 1) {
    const item = countItems[0];
    return `Includes ${item.count} ${item.name}${item.count > 1 ? "s" : ""}.`;
  }

  return `Includes ${countItems
    .map((item) => `${item.count} ${item.name}${item.count > 1 ? "s" : ""}`)
    .join(" and ")}.`;
}
