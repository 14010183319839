import { Spinner, Stack, Text } from "flicket-ui";
import { useAtom } from "jotai";
import { CSSProperties } from "react";
import { Editor, Range, Transforms, Text as TextNode } from "slate";
import {
  ReactEditor,
  RenderElementProps,
  useEditor,
  useSelected,
  useSlate,
} from "slate-react";
import { useTheme } from "styled-components";
import { ExtendedFile } from "~graphql/sdk";
import { useSDK } from "~hooks";
import { getError, getGCSFile, getImage, showToast } from "~lib";
import { delayedPromise } from "~lib/helpers/handlePromise";
import { Icon } from "../Icon";
import { imageModalAtom } from "./InsertImageModal";
import { Button } from "./components";
import isEqual from "lodash/isEqual";
import {
  EmptyParagraph,
  selectedBannerImage,
  topOfDocumentSelection,
} from "./util";
import { PencilSimple, Trash } from "@phosphor-icons/react";
import { useQuery } from "~hooks/useQuery";
import { DynamicImageDocument } from "~graphql/typed-document-nodes";
import { ImageElement } from "./interface/slate.interface";
import { isParagraphElement } from "./interface/slate.predicates";
import useEmailDataContext from "~features/emailCustomisation/hooks/useEmailDataContext";
import { usePopover } from "./Popover/usePopover";
import { UpDownPopoverButtons } from "./UpDown/UpDown";
import { StyledPopover } from "./Popover/Popover";

export const insertImage = (
  editor: Editor,
  image: ImageElement,
  isBanner: boolean
) => {
  let blurSelection = editor.blurSelection;

  if (!blurSelection || isBanner) {
    blurSelection = topOfDocumentSelection;
  }

  Transforms.insertNodes(editor, image, {
    at: blurSelection,
  });

  // add 1 to focus on the image
  const newRange: Range = {
    anchor: {
      offset: 0,
      path: [blurSelection?.anchor.path[0] + 1, 0],
    },
    focus: {
      offset: 0,
      path: [blurSelection?.focus.path[0] + 1, 0],
    },
  };

  // If this is at the top of the document
  if (isEqual(blurSelection, topOfDocumentSelection)) {
    const node = editor.children[blurSelection.anchor.path[0]];
    if (
      isParagraphElement(node) &&
      TextNode.isText(node.children[0]) &&
      node.children[0].text === ""
    ) {
      Transforms.removeNodes(editor, {
        at: blurSelection,
      });
      Transforms.insertNodes(editor, EmptyParagraph, {
        at: blurSelection,
      });
    }
    editor.selection = blurSelection;
  } else {
    editor.selection = newRange;
  }
  // add a paragraph so the user can edit access the next line in the editor
  Transforms.insertNodes(editor, [EmptyParagraph]);
  ReactEditor.focus(editor);
};

interface EditImageButtonProps {
  image: ImageElement | null;
  isBanner: boolean;
}

export const EditImageButton = (props: EditImageButtonProps) => {
  const { image, isBanner } = props;
  const [, setImageModalState] = useAtom(imageModalAtom);

  if (!image) {
    return null;
  }

  return (
    <>
      <Button
        onClick={() =>
          setImageModalState({
            isOpen: true,
            image,
            isBanner,
          })
        }
      >
        <Icon icon={<PencilSimple />} mr={"1/2"} />
        <Text variant="regular">Edit</Text>
      </Button>
    </>
  );
};

export const DeleteImageButton = () => {
  const editor = useSlate();

  return (
    <Button
      onClick={() => {
        editor.deleteBackward("block");
      }}
    >
      <Icon icon={<Trash />} mr={"1/2"} />
      <Text variant="regular">Delete image</Text>
    </Button>
  );
};

export const uploadImage = async (
  file: File,
  sdk: ReturnType<typeof useSDK>
): Promise<string> => {
  const newFile = Object.assign(file, {
    preview: URL.createObjectURL(file),
  });

  const [error, data] = await delayedPromise(async () =>
    sdk.uploadImage({
      file: {
        file: newFile,
      },
    })
  );

  if (error) {
    showToast(getError(error, "graphQL"), "error");
    return Promise.reject(error);
  }

  return getGCSFile(data.uploadImage as ExtendedFile);
};

export const fileSelectHandler = async (
  file: File,
  editor: Editor,
  sdk: ReturnType<typeof useSDK>,
  isBanner: boolean
): Promise<void | Error> => {
  const url = await uploadImage(file, sdk);

  const image: ImageElement = {
    type: "image",
    url,
    size: isBanner ? "full" : "large",
    children: [],
  };

  insertImage(editor, image, isBanner);
};

export const withImages = (editor: Editor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  return editor;
};

export function EmailImageLoading() {
  return (
    <Stack
      width="100%"
      height="200px"
      background="rgba(0,0,0,0.1)"
      alignItems="center"
      justifyContent={"center"}
      direction={"vertical"}
      mb={2}
    >
      <Spinner size={30} />
    </Stack>
  );
}

export const ImageComponent = ({
  attributes,
  children,
  element,
}: RenderElementProps & {
  element: ImageElement;
}) => {
  const [, setImageModalState] = useAtom(imageModalAtom);
  const selected = useSelected();
  const theme = useTheme();
  const editor = useSlate();
  const { isPopoverOpen, popoverRef } = usePopover("image");

  const banner = selectedBannerImage(editor);

  const context = useEmailDataContext();
  const loadDynamicImage =
    !element.url &&
    (element?.dynamic?.type === "eventBanner" ||
      element?.dynamic?.type === "membershipBanner");

  const { data, isLoading: dynamicImageLoading } = useQuery(
    loadDynamicImage && DynamicImageDocument,
    {
      input: {
        eventId: context?.type === "event" ? context?.id : undefined,
        membershipId: context?.type === "membership" ? context?.id : undefined,
      },
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  let imageUrl = element.url ?? "/static/no-image.jpg";

  if (loadDynamicImage && data?.dynamicImage) {
    imageUrl = getImage(data?.dynamicImage as ExtendedFile);
  }

  // This will be the path to the image element.
  const path = ReactEditor.findPath(editor, element);

  const isBannerImage = element.size === "full" && path[0] === 0;

  let imageStyle: CSSProperties = {
    cursor: "pointer",
  };

  if (element.size) {
    imageStyle = {
      ...imageStyle,
      width:
        element.size === "small"
          ? "33%"
          : element.size === "medium"
          ? "50%"
          : element.size === "large"
          ? "100%"
          : "calc(100% + 48px)",
      ...(element.size === "full" && {
        marginLeft: "-24px",
      }),
      ...(isBannerImage && {
        marginTop: "-32px",
      }),
      ...(element.size !== "full" && {
        margin: `auto ${element.align === "right" ? "0" : "auto"} auto ${
          element.align === "left" || !element.align ? "0" : "auto"
        }`,
      }),
    };
  }

  return (
    <div {...attributes} style={{ position: "relative", ...imageStyle }}>
      {/* children must be passed even not used https://github.com/ianstormtaylor/slate/issues/3930 */}
      {children}
      {dynamicImageLoading ? (
        <EmailImageLoading />
      ) : (
        <div contentEditable={false}>
          <img
            style={{
              minWidth: "100%",
              borderRadius: element.size === "full" ? 0 : "4px",
              boxShadow: selected ? `0 0 0 2px ${theme.colors.N800}` : "none",
              marginBottom: isBannerImage ? "45px" : "0",
            }}
            src={imageUrl}
          />
          {isPopoverOpen && (
            <StyledPopover ref={popoverRef}>
              {!banner && <UpDownPopoverButtons />}
              <Button
                w="30px"
                h="30px"
                onClick={() => {
                  setImageModalState({
                    isOpen: true,
                    image: element,
                    isBanner: false,
                  });
                }}
              >
                <Icon icon={<PencilSimple size={18} />} />
              </Button>

              {!banner && (
                <Button
                  w="30px"
                  h="30px"
                  onClick={() => {
                    editor.deleteBackward("character");
                  }}
                >
                  <Icon icon={<Trash size={18} />} />
                </Button>
              )}
            </StyledPopover>
          )}
        </div>
      )}
    </div>
  );
};
