import { SuggestedLinkType } from "./interface/slate.interface";

export interface ContentEntityValue {
  id: string;
  name: string;
}
export interface InsertLinkModalContentState {
  type?: ModalType;
  url?: string;
  urlDisplayText?: string;
  suggestedLink?: SuggestedLinkType;
  selectEvents?: boolean;
  event?: ContentEntityValue;
  release?: ContentEntityValue;
  membership?: ContentEntityValue;
}

export type InsertModalContentProps = {
  setIsOpen: (value: boolean) => void;
  isEdit?: boolean;
  isEmail?: boolean;
  defaultValues?: InsertLinkModalContentState;
  onSuccess: (state: InsertLinkModalContentState) => void;
};

export type ModalType = "link" | "button";
export type ModalOption = {
  type: ModalType;
  label: string;
};

export const modalOptions: ModalOption[] = [
  {
    type: "link",
    label: "Text link",
  },
  {
    type: "button",
    label: "Button",
  },
];

export const headerTextMap: Record<SuggestedLinkType, string> = {
  "access-your-tickets": "Link to their tickets",
  "event-information": "Link to event page",
  "event-registration": "Link to event registration",
  "event-tickets": "Link to tickets page",
  "event-ticket-resale": "Link to tickets page",
  "membership-renewal": "Link to membership renewal",
  "event-membership": "Link to ticketing page",

  "abandoned-cart-reengagement": "Abandoned cart re-engagement",
  "event-terms-and-conditions": "Event terms and conditions",
  "membership-terms-and-conditions": "Membership terms and conditions",
  "report-spam": "Report spam link",
  "registration-confirmation-share": "Share link to registration",
  unsubscribe: "Unsubcsribe link",
  "guest-tickets": "Link to guest tickets",
  "exhibitor-login": "Exhibitor login",
  "exhibitor-attendee-scans": "Exhibitor attendee scans",
};
