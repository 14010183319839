import { ReactEditor } from "slate-react";
import { Range } from "slate";
import isEqual from "lodash/isEqual";
import { ParagraphElement } from "./interface/slate.interface";
import {
  isImageElement,
  isMerchandiseElement,
  isSummaryElement,
  isVideoElement,
} from "./interface/slate.predicates";

export const topOfDocumentPoint = {
  offset: 0,
  path: [0, 0],
};

export const topOfDocumentSelection = {
  anchor: topOfDocumentPoint,
  focus: topOfDocumentPoint,
};

export const selectedImage = (editor: ReactEditor) => {
  const { selection } = editor;
  const node = editor.children?.[selection?.anchor?.path?.[0]];

  const isBannerImage =
    isImageElement(node) &&
    node.size === "full" &&
    isEqual(selection, topOfDocumentSelection);

  const isImageSelected =
    selection &&
    Range.isCollapsed(selection) &&
    isImageElement(node) &&
    !isBannerImage;

  return isImageSelected ? node : null;
};

export const selectedBannerImage = (editor: ReactEditor) => {
  const { selection } = editor;
  const node = editor.children?.[selection?.anchor?.path?.[0]];

  const isBannerImage =
    isImageElement(node) &&
    node.size === "full" &&
    isEqual(selection, topOfDocumentSelection);

  return isBannerImage ? node : null;
};

export const selectedVideo = (editor: ReactEditor) => {
  const { selection } = editor;
  const node = editor.children?.[selection?.anchor?.path?.[0]];

  const isVideoSelected =
    selection && Range.isCollapsed(selection) && isVideoElement(node);

  return isVideoSelected ? node : null;
};

export const selectedSummary = (editor: ReactEditor) => {
  const { selection } = editor;
  const node = editor.children?.[selection?.anchor?.path?.[0]];

  const isSummarySelected =
    selection && Range.isCollapsed(selection) && isSummaryElement(node);

  return isSummarySelected ? node : null;
};

export const selectedMerchandise = (editor: ReactEditor) => {
  const { selection } = editor;
  const node = editor.children?.[selection?.anchor?.path?.[0]];

  const isSummarySelected =
    selection && Range.isCollapsed(selection) && isMerchandiseElement(node);

  return isSummarySelected ? node : null;
};

export const EmptyParagraph: ParagraphElement = {
  type: "paragraph",
  children: [{ text: "" }],
};
